





















import Vue from 'vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import { TranslateResult } from 'vue-i18n'

export default Vue.extend({
  name: 'AcknowledgeModal',
  components: { CancelButton },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String as () => TranslateResult,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
  },
  methods: {
    submit() {
      this.$emit('acknowledged')
    },
  },
})
