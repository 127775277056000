






























import Vue from 'vue'
import ConfirmButton from '@/components/buttons/ConfirmButton.vue'
import CardTitle from '@/components/CardTitle.vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import { TranslateResult } from 'vue-i18n'

export default Vue.extend({
  name: 'ConfirmationModal',
  components: { ConfirmButton, CancelButton, CardTitle },
  props: {
    large: {
      type: Boolean,
      default: null,
    },
    title: {
      type: String as () => TranslateResult,
      default: '',
    },
    text: {
      type: String as () => TranslateResult,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: null,
    },
  },
  data: function (): {
    dialog: boolean
  } {
    return {
      dialog: false,
    }
  },
  methods: {
    submit() {
      this.dialog = false
      this.$emit('confirmed')
    },
  },
})
