






















import Vue from 'vue'
import CardTitle from '@/components/CardTitle.vue'
export default Vue.extend({
  name: 'BlankModal',
  components: { CardTitle },
  model: {
    prop: 'showModal',
  },
  props: {
    modalWidth: {
      type: String,
      default: '700',
    },
    buttonLabel: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
})
