







import Vue from 'vue'
export default Vue.extend({
  name: 'TenderDetailItem',
  props: {
    width: { type: String || Number, default: '150' },
    loading: { type: Boolean || undefined, default: false },
  },
})
