





























































































import Vue from 'vue'
import ConfirmationModal from '@/views/Tender/components/ConfirmationModal.vue'
import AcknowledgeModal from '@/views/Tender/components/AcknowledgeModal.vue'
import TenderService from '@/services/tender'
import { TranslateResult } from 'vue-i18n'
import moment from 'moment'
import { BidDTO } from '@/services/tender/types'

export default Vue.extend({
  name: 'BidActions',
  components: { AcknowledgeModal, ConfirmationModal },
  props: {
    bid: {
      type: Object as () => BidDTO,
      required: true,
    },
    type: {
      type: String,
      default: '',
    },
  },
  data: function (): {
    showAcknowledgeModal: boolean
    acknowledgeText: TranslateResult
    acknowledgeColor: string
  } {
    return {
      showAcknowledgeModal: false,
      acknowledgeText: '',
      acknowledgeColor: '',
    }
  },
  methods: {
    refreshTenderDetails() {
      this.$emit('refresh')
      this.showAcknowledgeModal = false
    },
    async generateContract(): Promise<void> {
      const date = moment().format('DD-MM-YYYY HH-mm')
      try {
        const response = await TenderService.generateContract(this.bid.id)
        let fileURL = response.data.data.uploadedContract
        let fURL = document.createElement('a')
        fURL.href = fileURL
        fURL.setAttribute('download', `contract-${date}.docx`)
        document.body.appendChild(fURL)
        fURL.click()
        this.showAcknowledgeModal = true
        this.acknowledgeColor = 'green'
        this.acknowledgeText = this.$t('generateContractSuccess')
      } catch (e) {
        this.showAcknowledgeModal = true
        this.acknowledgeColor = 'red'
        this.acknowledgeText = this.$t('generateContractError')
        console.log('Error: ', e)
      }
    },
    async downloadContract() {
      const date = moment().format('DD-MM-YYYY HH-mm')
      try {
        const response = await TenderService.downloadContract(this.bid.contract.id)
        let fileURL = response.data.data.uploadedContract
        let fURL = document.createElement('a')
        fURL.href = fileURL
        fURL.setAttribute('download', `contract-${date}.docx`)
        document.body.appendChild(fURL)
        fURL.click()
        this.showAcknowledgeModal = true
        this.acknowledgeColor = 'green'
        this.acknowledgeText = this.$t('downloadContractSuccess')
      } catch (e) {
        this.showAcknowledgeModal = true
        this.acknowledgeColor = 'red'
        this.acknowledgeText = this.$t('downloadContractError')
        console.log('Error: ', e)
      }
    },
    async sendContract() {
      try {
        await TenderService.sendContract(this.bid.contract.id)
        this.showAcknowledgeModal = true
        this.acknowledgeColor = 'green'
        this.acknowledgeText = this.$t('sendContractSuccess')
      } catch (e) {
        this.showAcknowledgeModal = true
        this.acknowledgeColor = 'red'
        this.acknowledgeText = this.$t('sendContractError')
        console.log('Error: ', e)
      }
    },
    async deleteContract() {
      try {
        await TenderService.deleteContract(this.bid.contract.id)
        this.showAcknowledgeModal = true
        this.acknowledgeColor = 'green'
        this.acknowledgeText = this.$t('deleteContractSuccess')
      } catch (e) {
        this.showAcknowledgeModal = true
        this.acknowledgeColor = 'red'
        this.acknowledgeText = this.$t('deleteContractError')
        console.log('Error: ', e)
      }
    },
    async generateInvoice() {
      const date = moment().format('DD-MM-YYYY HH-mm')
      try {
        const response = await TenderService.generateInvoice(this.bid.id)
        if (this.type !== 'buy') {
          let fileURL = response.data.data.uploadedInvoice
          let fURL = document.createElement('a')
          fURL.href = fileURL
          fURL.setAttribute('download', `invoice-${date}.docx`)
          document.body.appendChild(fURL)
          fURL.click()
        }
        this.showAcknowledgeModal = true
        this.acknowledgeColor = 'green'
        this.acknowledgeText = this.$t('generateInvoiceSuccess')
      } catch (e) {
        this.showAcknowledgeModal = true
        this.acknowledgeColor = 'red'
        this.acknowledgeText = this.$t('generateInvoiceError')
        console.log('Error: ', e)
      }
    },
    async downloadInvoice() {
      const date = moment().format('DD-MM-YYYY HH-mm')
      try {
        const response = await TenderService.downloadInvoice(this.bid.invoices[0].id)
        let fileURL = response.data.data.uploadedInvoice
        let fURL = document.createElement('a')
        fURL.href = fileURL
        fURL.setAttribute('download', `invoice-${date}.docx`)
        document.body.appendChild(fURL)
        fURL.click()
        this.showAcknowledgeModal = true
        this.acknowledgeColor = 'green'
        this.acknowledgeText = this.$t('downloadInvoiceSuccess')
      } catch (e) {
        this.showAcknowledgeModal = true
        this.acknowledgeColor = 'red'
        this.acknowledgeText = this.$t('downloadInvoiceError')
        console.log('Error: ', e)
      }
    },
    async sendInvoice() {
      try {
        await TenderService.sendInvoice(this.bid.invoices[0].id)
        this.showAcknowledgeModal = true
        this.acknowledgeColor = 'green'
        this.acknowledgeText = this.$t('sendInvoiceSuccess')
      } catch (e) {
        this.showAcknowledgeModal = true
        this.acknowledgeColor = 'red'
        this.acknowledgeText = this.$t('sendInvoiceError')
        console.log('Error: ', e)
      }
    },
    async deleteInvoice() {
      try {
        await TenderService.deleteInvoice(this.bid.invoices[0].id)
        this.showAcknowledgeModal = true
        this.acknowledgeColor = 'green'
        this.acknowledgeText = this.$t('deleteInvoiceSuccess')
      } catch (e) {
        this.showAcknowledgeModal = true
        this.acknowledgeColor = 'red'
        this.acknowledgeText = this.$t('deleteInvoiceError')
        console.log('Error: ', e)
      }
    },
  },
  computed: {
    isBuyType(): boolean {
      return this.type === 'buy'
    },
  },
})
