





















































































































































import { formatDate, renderDeliveryProfile, parseAndJoin, formatDateTime, formatDateTimeAL } from '@/utils/helpers'
import { defineComponent } from '@vue/composition-api'
import BidActions from '@/views/Tender/components/BidActions.vue'
import { TenderWinnersDTO } from '@/services/tender/types'
import { BidStatus } from '@/enums/BidStatus'
import LotWinningStatus from '@/views/Tender/partials/LotWinningStatus.vue'

export default defineComponent({
  name: 'TenderBid',
  setup() {
    return { formatDate, renderDeliveryProfile, parseAndJoin, formatDateTime, formatDateTimeAL }
  },
  components: { LotWinningStatus, BidActions },
  props: {
    lot: {
      type: Object as () => TenderWinnersDTO,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
    biddable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    profileInfo: {
      get(): string {
        return renderDeliveryProfile(this.lot.deliveryProfile ?? [])
      },
      set(value: string): void {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    refreshTenderDetails() {
      this.$emit('refresh')
    },
    calculatedTotal(pricePerUnit: number, quantity: number): number {
      return pricePerUnit && quantity ? pricePerUnit * quantity : 0
    },
    calculatedRemainingPower(offeredPower: number, purchasedPower: number): number {
      return offeredPower && purchasedPower ? offeredPower - purchasedPower : 0
    },
    calculatedQuantity(power: number, hours: number): number {
      return power && hours ? power * hours : 0
    },
    isWinnerLot(key: number) {
      return this.lot.lotBids[key].isWinner && this.lot.lotBids[key].status === BidStatus.CONFIRMED
    },
    isNotWinnerLot(key: number) {
      return !this.lot.lotBids[key].isWinner && this.lot.lotBids[key].status === BidStatus.NOT_APPROVED
    },
    shouldDisplayStatus(key: number) {
      return this.isWinnerLot(key) || this.isNotWinnerLot(key)
    },
    isPending(key: number) {
      return this.lot.lotBids[key].status === BidStatus.PENDING
    },
  },
})
