













import Vue from 'vue'
import { downloadBuffer } from '@/utils/helpers'

export default Vue.extend({
  name: 'DownloadButton',
  props: {
    id: { type: Number, default: 0 },
    leftIcon: { type: String, default: 'mdi-cloud-download-outline' },
    method: { type: Function, required: true },
    property: { type: String, required: true },
    label: { type: String, default: '' },
    fileName: { type: String, default: 'document' },
    fileType: { type: String, default: 'pdf' },
  },
  methods: {
    async downloadItem() {
      const loader = this.$loading.show()
      try {
        const { data } = await this.method(this.id)
        if (data) {
          await downloadBuffer(`${this.fileName}.${this.fileType}`, data.data[this.property].data)
          this.$emit('downloaded')
          this.$toast.success(data.message)
        }
      } catch (errors) {
        this.$toast.error(errors.response.data.message)
      } finally {
        loader.hide()
      }
    },
  },
})
