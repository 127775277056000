





































































import Vue from 'vue'
import TenderStatus from '@/views/Tender/partials/TenderStatus.vue'
import { evaluationIntervals, EvaluationIntervals, formatTimeWithTimeLabel } from '@/utils/helpers'
import TenderDetailItem from '@/components/Tender/TenderDetailItem.vue'
import BlankModal from '@/components/BlankModal.vue'
import TenderService from '@/services/tender'
import DownloadButton from '@/components/buttons/DownloadButton.vue'

export default Vue.extend({
  name: 'TenderHeader',
  components: { DownloadButton, TenderDetailItem, TenderStatus, BlankModal },
  data() {
    return {
      TenderService,
    }
  },
  props: {
    procurement: {
      type: Object,
      default: () => {
        return {
          id: 0,
          name: '',
          type: '',
          status: '',
          timeRemaining: 0,
          description: '',
          publishDate: '',
          endDate: '',
          publishTime: '',
          endTime: '',
          lots: [],
          creator: undefined,
        }
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    creatorName(): string {
      return this.procurement.creator
        ? `${this.procurement.creator.firstName} ${this.procurement.creator.lastName}`
        : ''
    },
    evaluationIntervals(): EvaluationIntervals {
      return evaluationIntervals(
        this.procurement.endTime,
        this.procurement.endDate,
        this.procurement.offerValidityHours || 0,
        this.procurement.offerValidityMinutes || 0,
      )
    },
  },
  methods: {
    formatTimeWithTimeLabel,
  },
})
