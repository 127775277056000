







import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'LotWinningStatus',
  props: { isWinner: { type: Boolean, default: false } },
  computed: {
    color(): { chip: string; text: string } {
      return this.isWinner ? { chip: '#DFF4E0', text: 'green' } : { chip: '#FFE5E7', text: 'red' }
    },
  },
})
